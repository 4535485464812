// https://coolors.co/ffffff-f2f2f2-d7ffdc-038f8c-0a3743-f29f06
$white-smoke: #f2f2f2;
$nyanza: #d7ffdc;
$dark-cyan: #038F8C;
$midnight-green: #0a3743;
$gamboge: #F29F06;

// Colours
$white:     #fff !default;
$gray-base: #181b1e !default;
$gray-100:  #f0f3f5 !default; // lighten($gray-base, 85%);
$gray-200:  #e4e7ea !default; // lighten($gray-base, 80%);
$gray-300:  #c8ced3 !default; // lighten($gray-base, 70%);
$gray-400:  #acb4bc !default; // lighten($gray-base, 60%);
$gray-500:  #8f9ba6 !default; // lighten($gray-base, 50%);
$gray-600:  #73818f !default; // lighten($gray-base, 40%);
$gray-700:  #5c6873 !default; // lighten($gray-base, 30%);
$gray-800:  #2f353a !default; // lighten($gray-base, 10%);
$gray-900:  #23282c !default; // lighten($gray-base, 5%);
$black:     #000 !default;


$blue:       #20a8d8 !default;
$indigo:     #6610f2 !default;
$purple:     #6f42c1 !default;
$pink:       #e83e8c !default;
$red:        #f86c6b !default;
$orange:     #f8cb00 !default;
$yellow:     #ffc107 !default;
$green:      #4dbd74 !default;
$teal:       #20c997 !default;
$cyan:       #17a2b8 !default;
$light-blue: #63c2de !default;



$primary:       $dark-cyan !default;
$secondary:     $midnight-green !default;
$success:       $green !default;
$info:          $light-blue !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;

// $primary:       $blue !default;
// $secondary:     $gray-300 !default;
// $success:       $green !default;
// $info:          $light-blue !default;
// $warning:       $yellow !default;
// $danger:        $red !default;
// $light:         $gray-100 !default;
// $dark:          $gray-800 !default;