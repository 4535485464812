// Import CoreUI default colors
@import "_colours";
@import "~@coreui/coreui-pro/scss/variables/colors";


// Customized Bootstrap variables
@import "~@coreui/coreui-pro/scss/variables/bootstrap/variables";
// Body
$body-bg: #f5f8fa;

// Colours
// $secondary:     $gray-600 !default;
// $secondary: #123456 !default;

// Typography
$font-family-sans-serif: "DM Sans", "Raleway", sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Font Awesome
// $fa-font-path: "/fonts";
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

$print-page-size: a3 !default;
$print-body-min-width: map-get($grid-breakpoints, "md") !default;

// Header
$zindex-sticky: 9999;

// Breadcrumb
$breadcrumb-padding-y: 0.5rem !default;

// Breadcrumbs

$breadcrumb-padding-y:              .75rem !default;
$breadcrumb-padding-x:              1rem !default;
$breadcrumb-item-padding:           .5rem !default;

$breadcrumb-margin-bottom:          1rem !default;

$breadcrumb-bg:                     $gray-200 !default;
// $breadcrumb-divider-color:          $gray-600 !default;
$breadcrumb-divider-color:          $midnight-green !default;
// $breadcrumb-active-color:           $gray-600 !default;
$breadcrumb-active-color:           $secondary !default;
$breadcrumb-divider:                quote("/") !default;

$breadcrumb-border-radius:          $border-radius !default;

// Cards

$card-spacer-y:                     .75rem !default;
$card-spacer-x:                     1.25rem !default;
// $card-border-width:                 $border-width !default;
// $card-border-radius:                $border-radius !default;
$card-border-color:                 rgba($black, .125) !default;
// $card-inner-border-radius:          calc(#{$card-border-radius} - #{$card-border-width}) !default;
$card-cap-bg:                       rgba($black, .03) !default;
// $card-cap-bg:                       $red !default;
$card-cap-color:                    null !default;
$card-color:                        null !default;
$card-bg:                           $white !default;

$card-img-overlay-padding:          1.25rem !default;

// $card-group-margin:                 $grid-gutter-width / 2 !default;
// $card-deck-margin:                  $card-group-margin !default;

$card-columns-count:                3 !default;
$card-columns-gap:                  1.25rem !default;
$card-columns-margin:               $card-spacer-y !default;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding:          .75rem !default;
$table-cell-padding-sm:       .3rem !default;

$table-color:                 $body-color !default;
$table-bg:                    null !default;
$table-accent-bg:             rgba($black, .05) !default;
$table-hover-color:           $table-color !default;
$table-hover-bg:              rgba($black, .075) !default;
$table-active-bg:             $table-hover-bg !default;

// $table-border-width:          $border-width !default;
$table-border-color:          $border-color !default;

$table-head-bg:               $gray-200 !default;
$table-head-color:            $gray-700 !default;

$table-dark-color:            $white !default;
// $table-dark-bg:               $gray-800 !default;
$table-dark-bg:               $secondary !default;
$table-dark-accent-bg:        rgba($white, .05) !default;
$table-dark-hover-color:      $table-dark-color !default;
$table-dark-hover-bg:         rgba($white, .075) !default;
$table-dark-border-color:     lighten($table-dark-bg, 7.5%) !default;
$table-dark-color:            $white !default;

$table-striped-order:         odd !default;

// $table-caption-color:         $text-muted !default;

$table-bg-level:              -9 !default;
$table-border-level:          -6 !default;

// remove sidebar for am-form views
// $sidebar-width: 400px !important;
// Forms
// $input-padding-x: 0.725rem;
// $position-values: (
//   6px: 6px,

// )