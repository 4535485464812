// Variables
@import '_variables';

// Fonts
@import url("https://fonts.googleapis.com/css?family=Raleway:300,400,600");
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');


// Font Awesome
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';

// Vuetify
// @import "~vuetify/dist/vuetify.min.css";
// @import url('https://fonts.googleapis.com/css?family=Material+Icons');
// @import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900');
// @import url('https://cdn.jsdelivr.net/npm/@mdi/font@5.x/css/materialdesignicons.min.css');

// Bootstrap
// @import '~bootstrap/scss/bootstrap';

// CoreUI
@import '~@coreui/coreui-pro/scss/coreui';
@import '~@coreui/icons/scss/coreui-icons';

// @import 'datatables.net-bs4/css/dataTables.bootstrap4.css';

// .navbar-laravel {
//   background-color: #fff;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
// }

@import '_vue-multiselect';
@import '_mixins';
@import '_custom';
// @import '_tinyMCE';
