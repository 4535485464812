// @import "~bootstrap/scss/variables";
@import "~@coreui/coreui-pro/scss/variables";

* {
  scroll-margin-top: 55px;
}

body {
  // overflow-y: hidden;
  // height: 100%;
  // overflow: hidden;

  &.pdf {
    background-color: #FFFFFF;
  }

  &.show-breakpoints:after {
    color: #000;
    font-family: Helvetica;
    font-size: 12px;
    padding: 5px;
    font-weight: bold;
    right: 10px;
    position: fixed;
    text-align: center;
    text-transform: uppercase;
    bottom: 10px;
    width: 200px;
    z-index: 9999;
    background: #c0c0c0;
    border: solid 1px #000;

    @each $name,
    $value in $grid-breakpoints {
      @include media-breakpoint-up($name) {
        content: '#{$name} - min-width: #{$value}';
      }
    }
  }
}

.header-fixed {
  .app-body {
    margin-top: 0px;
  }
}

.sidebar-fixed {
  .sidebar {
    margin-top: 55px;
  }
}

.breadcrumb {
  // position: fixed;
  // top: $navbar-height;
  // right: 0;
  // left: 0;
  // z-index: $zindex-sticky - 3;
  // padding-top: 0;
  // padding-left: 0;
  // padding-bottom: 0;
  // padding: 0 0 0 0;
  margin-bottom: 0;
  border-bottom: none;
}

.breadbar {
  display: flex;
  flex-wrap: wrap;
  padding: 0.25rem 0.5rem;
  // margin-bottom: 1.5rem;
  list-style: none;
  background-color: #fff;
  border-radius: 0;
  border-bottom: 1px solid #c8ced3;
  // position: relative;
}

.selectors {
  margin-top: 0.4rem;
}

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    html:not([dir="rtl"]) {

      .sidebar#{$infix}-show,
      .sidebar-show {
        .alert {
          left: $sidebar-width + 7px;
        }

        @if $breakpoint !="xs" {
          &.breadbar-fixed {
            .breadbar {
              left: $sidebar-width;
            }

            &.sidebar-compact {
              .breadbar {
                left: $sidebar-compact-width;
              }
            }

            &.sidebar-minimized {
              .breadbar {
                left: $sidebar-minimized-width;
              }
            }
          }
        }
      }

      .aside-menu-show,
      .aside-menu#{$infix}-show {
        .aside-menu {
          margin-right: 0;
        }

        @if $breakpoint !="xs" {
          &.breadbar-fixed {
            .breadbar {
              right: $aside-menu-width;
            }
          }
        }
      }
    }

    html[dir="rtl"] {

      .sidebar#{$infix}-show,
      .sidebar-show {
        .alert {
          left: $sidebar-width + 7px;

        }

        .sidebar {
          margin-right: 0;
        }

        @if $breakpoint !="xs" {
          &.breadbar-fixed {
            .breadbar {
              right: $sidebar-width;
            }

            &.sidebar-compact {
              .breadbar {
                right: $sidebar-compact-width;
              }
            }

            &.sidebar-minimized {
              .breadbar {
                right: $sidebar-minimized-width;
              }
            }
          }
        }
      }

      .aside-menu-show,
      .aside-menu#{$infix}-show {
        .aside-menu {
          margin-left: 0;
        }

        @if $breakpoint !="xs" {
          &.breadbar-fixed {
            .breadbar {
              left: $aside-menu-width;
            }
          }
        }
      }
    }
  }
}



// @media (min-width: 992px)
// html:not([dir=rtl]) .sidebar-lg-show.fixed-top, html:not([dir=rtl]) .sidebar-show.fixed-top {
//     left: 200px;
// }

.main {
  margin-top: 55px;
  margin-bottom: 0px;
  overflow: hidden;
  // background-color: red;

  .container-fluid {
    padding-top: 15px;
    // background-color: green;
    overflow: scroll;
    // height: calc(100% - 50px);
    // height: calc(100% - 72px);
    height: calc(100% - 33px);
  }
}

.adjusted-height {
  // background-color:red; /* For emphasis */
  // height: calc(100% - 72px);
  // overflow: scroll;
}

.bottom-6px {
  bottom: 6px;
}

.bottom-sticky {
  bottom: 0px;
  z-index: 1000;
}

// ALERTS
.alert {

  position: fixed;
  font-weight: bold;
  top: 63px;
  left: 7px;
  right: 7px;
  z-index: 9999;
  // margin-left: 1rem;
  // margin-right: 1rem;

  ul {
    list-style-type: none;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-inline-start: 0px;
  }
}

// BADGES

.badge {
  &.badge-lg {
    font-size: 100%;
  }
}

// CARDS
.card {
  // scroll-margin-top: 55px;
  max-width: 1200px;

  &.is-invalid {
    border-color: $red !important;
  }

  &#am-snapshot {
    width: 1020px;
  }

  .card-header {
    h4 {
      margin-bottom: 0;
    }
  }
}

.card-columns {

  // &.card-cols-2 {
  @include media-breakpoint-only(xl) {
    column-count: 4;
  }

  @include media-breakpoint-only(lg) {
    column-count: 3;
  }

  @include media-breakpoint-only(md) {
    column-count: 3;
  }

  @include media-breakpoint-only(sm) {
    column-count: 1;
  }

  // }
}

// CONTENTEDITABLE
[contenteditable]:focus {
  outline: 0px solid transparent;
}

// CURSORS
.cursor-pointer {
  cursor: pointer;

  &:hover {
    // cursor: pointer;
    // color: darken($red, 15%) !important;
    filter: brightness(85%);
  }
}

.cursor-delete {
  cursor: not-allowed;

  &:hover {
    // cursor: pointer;
    // color: darken($red, 15%) !important;
    filter: brightness(85%);
  }
}

// DRAG (VUE-DRAGGABLE)

[contenteditable="true"]:empty:before {
  content: attr(placeholder);
  pointer-events: none;
  display: block;
  /* For Firefox */
}

// .ghost {
//   opacity: 0.5;
//   // background-color: #c8ebfb;
//   background-color: $gray-300;
// }

// DROPBOX
.dropbox {
  outline: 2px dashed grey;
  /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  // padding: 10px 10px;
  min-height: 100px;
  /* minimum height */
  height: 100px;
  position: relative;
  cursor: pointer;
  display: flex;
}

.input-invisible {
  opacity: 0;
  /* invisible but it's there! */
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue;
  /* when mouse over to the drop zone, change color */
}

.dropbox p {
  margin: auto;
  // height: 100%;
  // margin-bottom: 0;
  // font-size: 1.2em;
  // text-align: center;
  // padding: 10px 10px;

}

// FOOTER
.app-footer {
  height: 50px;
}

.app-body {
  margin-bottom: 50px;
  // overflow-x: visible;
}

// SIDE BAR
.sidebar {
  background-color: $secondary;

  .nav {
    padding-bottom: 2rem;

    .nav-dropdown-items .nav-item {
      padding: 0 0 0 1rem;
      list-style: none;
    }
  }
}


// DATA TABLES
table.dataTable {
  tbody {
    tr {
      cursor: pointer;
      // &.row_deleted {
      //   display: none;
      // }
    }
  }

  thead {
    tr {
      th:not(.sorting_disabled) {
        padding-right: 35px !important;
      }
    }

    .sorting:before,
    .sorting_asc:before,
    .sorting_desc:before,
    .sorting_asc_disabled:before,
    .sorting_desc_disabled:before {
      right: 1.5em !important;
    }
  }
}

@mixin scrollbar(){
  &::-webkit-scrollbar {
      width: 14px;
      height: 18px;
  }
  &::-webkit-scrollbar-thumb {
      height: 6px;
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 7px;
      background-color: rgba(0, 0, 0, 0.15);
      box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05), inset 1px 1px 0px rgba(0, 0, 0, 0.05);
  }
  &::-webkit-scrollbar-button {
      width: 0;
      height: 0;
      display: none;
  }
  &::-webkit-scrollbar-corner {
      background-color: transparent;
  }

}

// TABLES
table {

  &.fixed-height {

    display: block;
    height: 400px;
    overflow-y: scroll;
    border-collapse: separate;
    border-spacing: 0;
    border: 1px solid $gray-300 !important;
    @include scrollbar();

  }

  &.fixed-width {
    table-layout: fixed;
  }

  // &.table-sm {
  //     th, td {
  //         padding: 0.75rem;
  //     }
  // }
  &.am-calendar {
    th {
      background-image: url('/images/icons/calendar-blank.svg');
      background-repeat: no-repeat;
      background-size: 3rem;
      background-position: center;
      padding-top: 0.75rem;
    }

    td {
      padding: 0.5rem;

      span {
        border: 2px solid;
        border-radius: 5px;
        padding: 0.4rem 0.6rem;
        font-weight: bold;
      }
    }
  }

  tbody {
    td {
      p {
        margin: 0.4rem 0;
      }

      i {

        // line-height: 1.6rem !important;
        &.icon-rating {
          margin: 0 0.1rem;
        }
      }
    }

    .w-10 {
      width: 10%;
    }

    .w-20 {
      width: 20%;
    }

    .w-30 {
      width: 30%;
    }

    .w-40 {
      width: 40%;
    }

    .w-50 {
      width: 50%;
    }

    .w-60 {
      width: 60%;
    }

    .w-70 {
      width: 70%;
    }

    .w-80 {
      width: 80%;
    }

    .w-90 {
      width: 90%;
    }

    .w-100 {
      width: 100%;
    }
  }
}

// FORMS


.text {
  &.required::after {
    content: "*";
    color: $red;
  }
}

.form-control {
  border-color: $primary;

  &:focus {
    border-color: $gamboge;
  }
}

.form-group {

  // Add red * for required fields
  label {
    &.required::after {
      content: "*";
      color: $red;
    }
  }

  input {

    &:valid:not(.is-invalid),
    &[data-valid='true'] {
      border-color: $green !important;
    }

    // &:invalid, &[data-valid='false'] {
    //     border-color: $red;
    // }
    &:invalid:not(.is-valid),
    &[data-valid='false'] {
      border-color: $red !important;
    }

    &.form-control-sm {
      border-style: solid;
      border-width: 1px;

      &.no-validation {
        border-color: $primary;
      }
    }

    &.no-border {
      border: none;
    }

    &.border-bottom {
      outline: 0;
      border-width: 0 0 2px;
      border-bottom: 2px solid $gray-300 !important;
      // border: 1px solid $primary;
      // border-radius: 0.25rem;
      box-shadow: none;

      &.form-control {
        &.is-invalid {
          border-color: $red !important;
        }
      }
    }
  }

  textarea {
    padding-left: 0.75rem;

    &.no-border {
      border: none;
    }

    &.resize-none {
      resize: none;
    }
  }

  .error-invalid {
    display: inline-block;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: $red;
  }

  // tinyMCE border validation

  .tinymce-wrapper {
    &.is-invalid {
      .tox-tinymce {
        border-color: $red;
      }
    }

    &.is-valid {
      .tox-tinymce {
        border-color: $green;
      }
    }
  }

  .is-invalid {
    .tox-tinymce {
      border-color: $red;
    }
  }

  .is-valid {
    .tox-tinymce {
      border-color: $green;
    }
  }
}

.form-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 50%;
  transition: .5s ease;
  background-color: $gray-300;
  z-index: 10;
}

@mixin custom-form-validation-state($state, $color, $icon) {
  .input-group {

    .was-validated &:#{$state},
    &.is-#{$state} {
      input {
        border-color: $color;
      }

      &:focus {
        border-color: $color;
        box-shadow: 0 0 0 $input-focus-width rgba($color, .25);
      }

      ~.#{$state}-feedback,
      ~.#{$state}-tooltip {
        display: block;
      }
    }
  }
}

@each $state,
$data in $form-validation-states {
  @include custom-form-validation-state($state, map-get($data, color), map-get($data, icon));
}


img {
  &.qrCode {
    margin: 1em 0;
  }
}

.tile {
  &-thumbnail {
    height: 100px;
    width: 100px;
  }
}

.select2 {
  width: 100% !important;
}

.select2-container--bootstrap {
  .select2-results__group {
    color: rgb(115, 129, 143, 0.85) !important;
    font-size: 0.9rem !important;
    font-weight: normal !important;
    // padding-left: 1.75rem!important;
  }

  .select2-results__option .select2-results__option {
    color: rgb(115, 129, 143, 1) !important;
    font-weight: bold !important;
    padding-left: 1.75rem !important;
  }

  .select2-selection--multiple {
    .select2-selection__choice__remove {
      background-color: transparent !important;
    }
  }

  .select2-results__option--highlighted {
    background-color: #038f8c !important;
    // background-color: #ff00ff !important;
    color: #fff;
  }
}

.tox-tinymce {
  border-radius: 0.25rem !important;
}

// FORM INPUT

// CHECKBOX
.custom-control {
  &.custom-checkbox {
    &.am-custom-checkbox {
      min-height: 2rem;
      line-height: 2rem;
      padding-left: 2rem;

      .custom-control-label {
        padding-left: 0.5rem;
      }

      .custom-control-label::before {
        border-radius: 0.25rem;
        height: 2rem;
        width: 2rem;
        top: 0;
        left: -2rem;
      }

      .custom-control-label::after {
        // position: absolute;
        // top: 0.22rem;
        // left: -2rem;
        // display: block;
        width: 2rem;
        height: 2rem;
        top: 0;
        left: -2rem;
        // content: "";
        // background: no-repeat 50%/50% 50%;
      }
    }
  }

  &.custom-radio {
    &.am-custom-radio {
      min-height: 2rem;
      line-height: 2rem;
      padding-left: 2rem;

      .custom-control-label {
        padding-left: 0.5rem;
      }

      .custom-control-label::before {
        // border-radius: 0.25rem;
        height: 2rem;
        width: 2rem;
        top: 0;
        left: -2rem;
      }

      .custom-control-label::after {
        // position: absolute;
        // top: 0.22rem;
        // left: -2rem;
        // display: block;
        width: 2rem;
        height: 2rem;
        top: 0;
        left: -2rem;
        // content: "";
        // background: no-repeat 50%/50% 50%;
      }
    }
  }
}

.amform-card {
  // background-color: green;
}

.amform-button-next {
  // background-color: cyan;
}

.amform-col-choices {
  // background: purple;
}

// BUTTON
.amform-button {
  border: 2px solid $primary;
  background-color: $white;
  color: $primary;
  font-weight: bold;

  &:hover {
    background: rgba($primary, 0.8);
    color: $white;
  }

  &:active {
    background: $primary;
    color: $white;
  }

  &.active {
    background: $primary;
    color: $white;
  }

  // SCALE BUTTON
  &.btn-scale {
    // margin-right: 1rem;
    // min-width: 3rem;
    padding-left: 0;
    padding-right: 0;
  }

}

.amform-button-accept {
  font-weight: bold;
  opacity: 0.8;

  &.selected {
    opacity: 1;
  }
}

// LISTS

.ghost {
  opacity: 0.5;
  // background-color: #c8ebfb;
  background-color: #c8ebfb;
}

.list-group-item {

  background-color: #fff;
  border: 1px solid $gray-300;

  &.is-invalid {
    border-color: $red !important;
    border-width: 2px;
  }

  &.ghost {
    opacity: 0.5;
    // background-color: #c8ebfb;
    background-color: #c8ebfb;
  }

  &.rounded-corners {
    border-radius: 0.25rem;
    // border-top-right-radius: 0.25rem;
  }

  .handle {
    cursor: grab;
  }

  .input-group {
    .input-group-prepend {
      .input-group-text {
        &.width-fixed-1 {
          width: 2.4rem;
        }
      }
    }

    &.is-required {
      .input-group-prepend {


        .input-group-text {
          background: $white;
          // border-color: $green;
          border-right: none;
          padding-right: 0;

          &.is-valid {
            border-color: $green !important;
          }

          &.is-invalid {
            border-color: $red !important;
          }

          &.is-disabled {
            background-color: #e4e7ea;
            opacity: 1;
          }
        }
      }

      input {
        border-left: none;
      }
    }
  }

  // background-color: #FFFFFF;
  // background: inherit;

  &.no-border {
    border: none;
  }

  // curved top corners for first row
  &.corners-curved-top {
    padding: 0 0 1rem;
    margin: 0px;

    .row {
      margin: 0px;
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;

      .col {
        // padding: 5px 5px 0 5px;
        // padding: 0 5px 0 5px;

        &:first-of-type {
          border-top-left-radius: inherit;
        }

        &:last-of-type {
          border-top-right-radius: inherit;
        }
      }
    }
  }

  // .row {
  //   &.corners-curved-top {
  //     border-top-left-radius: 0.25rem;
  //     border-top-right-radius: 0.25rem;
  //     margin-top: -13px;
  //     margin-left: -20px;
  //     margin-right: -21px;

  //     .col {
  //       &:first-of-type {
  //         border-top-left-radius: inherit;
  //       }

  //       &:last-of-type {
  //         border-top-right-radius: inherit;
  //       }

  //       // padding-left: 5px;
  //       // padding-right: 5px;
  //       padding: 5px

  //       // &:not(:first-of-type) {
  //       //   &:not(:last-of-type) {
  //       //     padding-left: 0px;
  //       //     padding-right: 0px;
  //       //   }
  //       // }
  //     }
  //   }
  // }

}



// Chart.js
.chartjs-tooltip {
  opacity: 1;
  position: absolute;
  background: rgba(0, 0, 0, .7);
  color: white;
  border-radius: 3px;
  -webkit-transition: all .1s ease;
  transition: all .1s ease;
  pointer-events: none;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.chartjs-tooltip-key {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 10px;
}

// TRANSITIONS
.fade-enter-active,
.fade-leave-active {
  transition: opacity .5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

// MODALS
body {

  .modal {
    z-index: 10000;
  }

  &:not(.modal-open) {
    padding-right: 0px !important;
  }

  .modal-open {
    overflow: visible;
    overflow-x: hidden;
    overflow-y: visible;


  }
}

// Scale up the modal
@include media-breakpoint-up(sm) {

  // Automatically set modal's width for larger viewports
  .modal-dialog {
    max-width: $modal-md;
    // max-width: 80%;
    margin: $modal-dialog-margin-y-sm-up auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - #{$modal-dialog-margin-y-sm-up * 2});

    .modal-content {
      max-height: calc(100vh - #{$modal-dialog-margin-y-sm-up * 2});
    }
  }

  .modal-dialog-centered {
    min-height: calc(100% - #{$modal-dialog-margin-y-sm-up * 2});

    &::before {
      height: calc(100vh - #{$modal-dialog-margin-y-sm-up * 2});
    }
  }

  .modal-content {
    @include box-shadow($modal-content-box-shadow-sm-up);
  }

  .modal-sm {
    max-width: $modal-sm;
  }
}

@include media-breakpoint-up(lg) {

  .modal-lg,
  .modal-xl {
    max-width: $modal-lg;
  }
}

@include media-breakpoint-up(xl) {
  .modal-xl {
    max-width: $modal-xl;
  }
}

// ICONS
.am-icon {
  &__progress {
    font-size: 2rem;
    margin-top: 0.25rem;
  }

  // &__text {
  //     vertical-align: super;
  // }
}

.am-tile {
  margin-right: 0.2rem;
  max-height: 40px;
  max-width: 40px;
  // width: 768px;
}

// ICON EFFECTS
.text-danger {
  &.hover-effect {
    &:hover {
      cursor: pointer;
      color: darken($red, 15%) !important;
    }
  }
}

.am_dot {
  font-size: 0.5rem;
  position: absolute;
  // top: 0;
  right: -0.6rem;

  // &.red {
  //     color: $red;
  // }
  // &.green {
  //     color: $green;
  // }
  &--black_cross {
    @extend .am_dot, .fas, .fa-times-circle, .text-dark;
  }

  &--green {
    @extend .am_dot, .fas, .fa-circle, .text-success;
  }

  &--green_plus {
    @extend .am_dot, .fas, .fa-plus-circle, .text-success;
  }

  &--red {
    @extend .am_dot, .fas, .fa-circle, .text-danger;
  }

  &--red_minus {
    @extend .am_dot, .fas, .fa-minus-circle, .text-danger;
  }
}


// TOOLTIP
.tooltip {
  display: block !important;
  z-index: 1000;

  .tooltip-inner {
    background: $primary;
    color: white;
    border-radius: 5px;
    padding: 5px 10px 4px;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: $primary;
    z-index: 1;
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.popover {
    $color: #f9f9f9;

    .popover-inner {
      background: $color;
      color: black;
      padding: 24px;
      border-radius: 5px;
      box-shadow: 0 5px 30px rgba(black, .1);
    }

    .popover-arrow {
      border-color: $color;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity .15s, visibility .15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity .15s;
  }
}

///////////////
// BOOTSTRAP //
///////////////


// PRINT

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @for $i from 1 through $grid-columns {

      @media print {
        .col-print#{$infix}-#{$i} {
          @include make-col($i, $grid-columns);
        }
      }
    }
  }
}


// @media print and (width: 11cm) and (height: 11cm) {
//    @page {
//       margin: 3cm;
//    }
// }
//
$print-page-size: a4 !default;
$print-body-min-width: map-get($grid-breakpoints, "lg") !default;
// body {
//   min-width: 300px !important;
// }
// .container {
//   min-width: $print-body-min-width !important;
// }

//
// @page {
//     size: auto;
// }

// TOOLTIP
.tooltip-inner {
  background-color: $primary;
}

.tooltip {
  &.bs-tooltip-top .arrow:before {
    border-top-color: $primary !important;
  }

  &.bs-tooltip-right .arrow:before {
    border-right-color: $primary !important;
  }

  &.bs-tooltip-bottom .arrow:before {
    border-bottom-color: $primary !important;
  }

  &.bs-tooltip-left .arrow:before {
    border-left-color: $primary !important;
  }
}